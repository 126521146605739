import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getMultiSearchCurrentValue } from 'redux/modules/multi-search/selectors';

import Refinements from './Refinements';

export default compose(
  withRouter,
  connect(state => {
    const { criteria, hasResults } = state.searchAndBrowse;
    const hasFilters = criteria && criteria.filters ? criteria.filters.length > 0 : false;

    return {
      hasRefinements: hasFilters || hasResults,
      isMultiSearch: getMultiSearchCurrentValue(state)?.length > 0,
    };
  }),
)(Refinements);
